<template>
  <li class="flex-auto text-center lg:w-1/3">
    <a
      class="text-xs font-bold uppercase px-2 py-2 shadow-lg rounded block leading-normal"
      v-bind:class="{
        'text-gray-700 bg-white': openTab !== tabIndex,
        'text-white bg-secondary': openTab === tabIndex,
      }"
    >
      {{ tabText }}
    </a>
  </li>
</template>
<script>
export default {
  name: "TabNavElement",
  props: {
    tabText: {
      type: String,
      required: true,
    },
    tabIndex: {
      type: Number,
      required: true,
    },
    openTab: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<template>
  <div class="bg-primary">
    <PageActionTitleComponent actionTitle="Willkommen!" />
    <div class="home-first-text w-full mb-2">
      <div class="flex flex-col items-center gap-4 md:flex-row mx-auto w-10/12">
        <div
          class="lg:h-48 xl:h-44 lg:flex lg:flex-col space-y-2 2xl:py-8 md:h-64 lg:py-6 p-4 bg-white rounded-lg shadow w-5/6 mr-4"
        >
          <p>
            Die <InkluLogo />-App ist für Menschen (Kund:innen), die auf Grund
            von gesundheitlichen Einschränkungen (physisch und psychisch) noch
            nicht den idealen Weg ins Arbeitsleben gefunden haben und den
            Menschen (Fallmanager:innen), die sie auf ihrem Weg unterstützen.
          </p>
          <p>
            <span class="font-semibold">Aufbau</span>: Die <InkluLogo />-App ist
            eine virtuelle Informations- und Begegnungsstätte. Die
            <InkluLogo />-App orientiert sich am Aufbau eines Hauses und
            empfindet Beratungswege digital nach.
          </p>
        </div>
        <div>
          <div class="relative max-w-xs">
            <img
              alt="Robin"
              class="border-4 shadow border-white rounded-lg h-72 w-72 lg:w-44 lg:h-44 md:h-64 md:w-56 object-cover object-center"
              :src="imageUrl('robin')"
            />
            <div class="absolute -mt-20 text-black px-4">
              <div class="text-md pl-1">
                <span class="font-semibold">Robin</span>
              </div>
              <div class="bg-white/50 py-1 px-2">
                <p class="font-semibold text-xs">
                  38, mit ADHS sucht einen Job
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PageActionTitleComponent :action-title="actionTitle" />
    <div class="bg-primary w-full pb-8">
      <div class="w-auto h-auto">
        <div class="flex flex-col lg:flex-row m-auto w-10/12">
          <div class="image-container relative w-full lg:w-8/12 xl:w-[711px]">
            <div class="flex flex-row absolute top-28 left-40">
              <div
                class="hidden xl:block cursor-pointer h-24 mr-24 rounded-lg w-36 text-black px-4"
                v-on:mouseover="setHoveredTabId(3)"
                v-on:mouseout="setHoveredTabId(null)"
              >
                <span class="hidden">Austausch</span>
              </div>
              <div
                class="hidden xl:block cursor-pointer h-24 rounded-lg w-40 text-black px-4"
                v-on:mouseover="setHoveredTabId(4)"
                v-on:mouseout="setHoveredTabId(null)"
              >
                <span class="hidden">Fachberatung</span>
              </div>
            </div>
            <div class="flex flex-row absolute top-56 left-40">
              <div
                class="hidden xl:block cursor-pointer h-32 mr-24 rounded-lg w-36 text-black px-4"
                v-on:mouseover="setHoveredTabId(2)"
                v-on:mouseout="setHoveredTabId(null)"
              >
                <span class="hidden">FAQ</span>
              </div>
              <div
                class="hidden xl:block cursor-pointer h-32 rounded-lg w-40 text-black px-4"
                v-on:mouseover="setHoveredTabId(5)"
                v-on:mouseout="setHoveredTabId(null)"
              >
                <span class="hidden">Jobbörse</span>
              </div>
            </div>
            <div class="flex flex-row absolute top-96 left-40">
              <div
                class="hidden xl:block cursor-pointer h-28 mr-24 rounded-lg w-36 text-black px-4"
                v-on:mouseover="setHoveredTabId(1)"
                v-on:mouseout="setHoveredTabId(null)"
              >
                <span class="hidden">Orientierung</span>
              </div>
              <div
                class="hidden xl:block cursor-pointer h-28 rounded-lg w-40 text-black px-4"
                v-on:mouseover="setHoveredTabId(6)"
                v-on:mouseout="setHoveredTabId(null)"
              >
                <span class="hidden">Ergebnisse</span>
              </div>
            </div>
            <img
              class="bg-white shadow-lg rounded-md"
              alt="Inklu Logo"
              :src="imageUrl('funktion')"
            />
          </div>
          <div class="description-tabs w-full pt-4 lg:pt-0 lg:w-4/12">
            <TabView
              v-model:hovered-tab-id="deliverHoveredTabId"
              class="lg:ml-4"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="home-text w-full mb-8">
      <div class="flex flex-col mx-auto w-10/12">
        <div class="px-4 py-3 bg-white rounded-lg shadow">
          <p></p>
          <p class="mb-4">
            <span class="font-semibold">Zielgruppe</span> <br /><br />
            Menschen mit gesundheitlichen Einschränkungen (physisch und
            psychisch), die noch nicht den idealen Einstieg in die die
            Arbeitswelt gefunden haben.
          </p>
          <p class="mb-4">
            <span class="font-semibold">Lösung</span> <br /><br />
            Als Ergänzung oder Ersatz (falls nicht vorhanden) bietet die
            <InkluLogo />-App Kund:innen und Fallmanager:innnen, ein digitales
            "Haus der Arbeit" als virtuellen Begnungsort an. <br /><br />
            Dort können Kund:innen sich orientieren und die für ihre Belange
            relevanten Fragen klären (FAQ). <br />
            Auf Wunsch kann sicher und anonym Kontakt mit Fallmanager:innen oder
            Anbieter:innen von Hilfs-, Förderinstitutionen sowie
            Arbeitgeber:innen aufgenommen werden (Chat). <br /><br />
            Mit der <InkluLogo /> können Termine oder Veranstaltungen verwaltet,
            Checklisten für benötigte Unterlagen und Erinnerungen werden, damit
            die Zusammenarbeit gelingt.
          </p>
          <p class="mb-4">
            <span class="font-semibold">Einsatzmöglichkeit</span> <br /><br />
            Die <InkluLogo />-App kann deutschlandweit angewendet werden, sollte
            aber auch regional begrenzt werden, damit die Angebote örtlich
            passend sind.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ImageAssetMixin from "@/mixin/ImageAssetMixin";
import TabView from "@/components/TabView.vue";
import PageActionTitleComponent from "@/components/PageActionTitleComponent.vue";
import InkluLogo from "@/pages/InkluLogo.vue";

export default defineComponent({
  name: "HomePage",
  mixins: [ImageAssetMixin],
  components: { TabView, PageActionTitleComponent, InkluLogo },
  data() {
    return {
      actionTitle: "Funktionen auf einen Blick",
      hoveredTabId: null,
    };
  },
  computed: {
    deliverHoveredTabId() {
      return this.hoveredTabId;
    },
  },
  methods: {
    setHoveredTabId(tabId) {
      this.hoveredTabId = tabId;
    },
  },
});
</script>

export default {
  methods: {
    imageUrl(image: string): string {
      const imageDictionary: Record<string, string> = {
        // General
        logo: "img/inkluapp-logo.svg",
        loginbold: "img/login-bold.svg",

        // home
        robin: "img/robin-has-questions.png",
        funktion: "img/inklu-funktion.svg",

        //partners
        jobcenterNortheim: "img/jobcenter-northeim.png",
        landkreisNortheim: "img/landkreis-northeim.jpg",
        softdoor: "img/softdoor-logo.png",

        // team
        seli: "img/Team-Seli.jpeg",
        katharina: "img/Team-Katharina.jpg",
        frank: "img/Team-Frank.jpg",

        // app
        architecture: "img/Inkluapp-Architektur.svg",
      };
      const imageElement = imageDictionary[image];

      return require(`@/assets/${imageElement}`);
    },
  },
};

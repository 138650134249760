<template>
  <div class="bg-primary h-screen md:h-full">
    <PageActionTitleComponent actionTitle="Datenschutz" />
    <div class="login-page-text w-full">
      <div class="flex flex-col mx-auto w-10/12">
        <div class="px-4 py-3 bg-white rounded-lg shadow">
          <p>
            Der Webserver speichert Zugriffen und IP-Adressen der Zugreifer in
            Logdateien. Diese dienen nur der Fehlersuche und werden ansonsten
            nicht ausgewertet.
          </p>
          <p>
            Es gibt keine Benutzerkonten, kein Tracking, keine Cookies.
            Logdateien betrachte ich daher nicht als personenbezogen.
          </p>
          <p>
            Ansonsten werden keine Daten erhoben, ausgewertet oder gespeichert.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageActionTitleComponent from "@/components/PageActionTitleComponent.vue";
export default {
  name: "DatenschutzPage",
  components: { PageActionTitleComponent },
};
</script>

<style scoped></style>

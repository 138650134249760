export interface InkluRoute {
  path: string;
  name: string;
}

export const HomeRoute: InkluRoute = {
  path: "/",
  name: "Start",
};

export const PartnersRoute: InkluRoute = {
  path: "/partners",
  name: "Partner",
};

export const TeamRoute: InkluRoute = {
  path: "/team",
  name: "Team",
};

export const AppRoute: InkluRoute = {
  path: "/app",
  name: "App",
};

export const LoginRoute: InkluRoute = {
  path: "/login",
  name: "Login",
};

export const DatenschutzRoute: InkluRoute = {
  path: "/datenschutz",
  name: "Datenschutz",
};

export const ImpressumRoute: InkluRoute = {
  path: "/impressum",
  name: "Impressum",
};

<template>
  <div class="flex flex-wrap">
    <div class="w-full">
      <ul class="flex mb-0 gap-x-2 gap-y-2 list-none flex-wrap pb-2 flex-row">
        <TabNavElement
          tab-text="1. Orientierung"
          :tab-index="1"
          :open-tab="getHoveredTabId"
          v-on:click="toggleTabs(1)"
        />
        <TabNavElement
          tab-text="2. FAQ"
          :tab-index="2"
          :open-tab="getHoveredTabId"
          v-on:click="toggleTabs(2)"
        />
        <TabNavElement
          tab-text="3. Austausch"
          :tab-index="3"
          :open-tab="getHoveredTabId"
          v-on:click="toggleTabs(3)"
        />
        <TabNavElement
          tab-text="4. Fachberatung"
          :tab-index="4"
          :open-tab="getHoveredTabId"
          v-on:click="toggleTabs(4)"
        />
        <TabNavElement
          tab-text="5. Jobbörse"
          :tab-index="5"
          :open-tab="getHoveredTabId"
          v-on:click="toggleTabs(5)"
        />
        <TabNavElement
          tab-text="6. Ergebnisse"
          :tab-index="6"
          :open-tab="getHoveredTabId"
          v-on:click="toggleTabs(6)"
        />
      </ul>
      <div
        class="relative flex flex-col min-w-0 break-words bg-white w-full mb-0 shadow-lg rounded"
      >
        <div class="px-4 py-5 flex-auto">
          <div class="tab-content tab-space">
            <div
              v-bind:class="{
                hidden: getHoveredTabId !== 1,
                block: getHoveredTabId === 1,
              }"
            >
              <p>
                <span class="font-semibold">1. Orientierung</span><br /><br />
                Übersicht aller Funktionen. Möglichkeit niederigschwellig per
                Suche oder per Fragebogen zu starten. <br /><br />
                Der Fragebogen ermittelt die aktuelle Situation.<br />
                Es folgen die Optionen: <br /><br />
                Lesen von Empfehlungen, anonymes Chatten, Hinterlegen von
                Kontaktdaten mit Wunsch von einer:m Fallmanager:in kontaktiert
                zu werden oder eigene proaktive Kontaktaufnahme.
              </p>
            </div>
            <div
              v-bind:class="{
                hidden: getHoveredTabId !== 2,
                block: getHoveredTabId === 2,
              }"
            >
              <p>
                <span class="font-semibold">2. FAQ</span> <br /><br />
                Hier werden die am häufigsten gestellten Fragen beantwortet.<br /><br />
                Außerdem werden die regionalen Anbieter der Teilhabeberatung mit
                deren Angeboten vorgestellt.
              </p>
            </div>
            <div
              v-bind:class="{
                hidden: getHoveredTabId !== 3,
                block: getHoveredTabId === 3,
              }"
            >
              <p>
                <span class="font-semibold">3. (Daten-)Austausch</span
                ><br /><br />
                Kund:innen können miteinander anonym oder direkt kommunizieren.
                Daten werden nur bei expliziter Erlaubnis von Kund:innen
                weitergegeben.
              </p>
              <br />
              <p>
                <span class="font-semibold">Sicherheit</span>: Verwendete Server
                stehen in Deutschland. Die Kommunikation ist verschlüsselt. Wir
                verzichten auf Tracking. <br />
                Nutzerdaten werden bis zum nicht-anonymen Kontaktwunsch mit
                Fallmanager:innen nur auf dem Endergeräten der Kund:innen
                gespeichert. Nutzerdaten können jederzeit vollständig gelöscht
                werden.
              </p>
            </div>

            <div
              v-bind:class="{
                hidden: getHoveredTabId !== 4,
                block: getHoveredTabId === 4,
              }"
            >
              <p>
                <span class="font-semibold">4. Fachberatung</span> <br /><br />
                Hier findet das eigentliche Beratungsgespräch statt. Dies kann
                selbstverständlich auch als Videokonferenz durchgeführt werden.
                <br /><br />
                Die Fallmanager:innen stellen sich mit ihrem Expertiseprofil
                vor.
              </p>
            </div>
            <div
              v-bind:class="{
                hidden: getHoveredTabId !== 5,
                block: getHoveredTabId === 5,
              }"
            >
              <p>
                <span class="font-semibold">5. Jobbörse</span> <br /><br />
                Um zu erleichtern, dass potentielle Mitarbeiter:innen und
                Arbeitgeber:innen zusammenfinden, können sich hier Unternehmen
                vorstellen, die für die Einstellung von Menschen mit inklusiven
                Voraussetzungen offen sind. <br /><br />
                Außerdem können hier Jobangebote platziert werden.
              </p>
            </div>
            <div
              v-bind:class="{
                hidden: getHoveredTabId !== 6,
                block: getHoveredTabId === 6,
              }"
            >
              <p>
                <span class="font-semibold">6. Ergebnisse</span> <br /><br />
                Einsicht in alle Informationen als eine Art „digitale Akte“.<br /><br />
                Somit ist es für die Kund:innen und die Fallmanager:innen zu
                jederzeit möglich, den aktuellen Beratungsstand einzusehen.<br /><br />
                Weiter können hier notwendige Dokumente und kurze Notizen und
                Chats ausgetauscht werden.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabNavElement from "@/components/TabNavElement";

export default {
  name: "TabView",
  components: { TabNavElement },
  props: ["hoveredTabId"],
  computed: {
    getHoveredTabId: function () {
      return this.hoveredTabId === null ? this.openTab : this.hoveredTabId;
    },
  },
  data() {
    return {
      openTab: this.hoveredTabId !== null ? this.hoveredTabId : 1,
    };
  },
  methods: {
    toggleTabs: function (tabNumber) {
      if (this.hoveredTabId !== null) {
        this.openTab = this.hoveredTabId;
      } else {
        this.openTab = tabNumber;
      }
    },
  },
};
</script>

<template>
  <div class="flex flex-col h-full md:h-screen">
    <NavigationBar />
    <router-view />
    <FooterComponent />
  </div>
</template>

<style lang="scss"></style>
<script>
import NavigationBar from "@/components/NavigationBar";
import FooterComponent from "@/pages/FooterComponent";

export default {
  components: { NavigationBar, FooterComponent },
};
</script>

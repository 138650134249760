import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import {
  HomeRoute,
  PartnersRoute,
  TeamRoute,
  AppRoute,
  LoginRoute,
  DatenschutzRoute,
  ImpressumRoute,
} from "@/router/Routes";
import HomePage from "@/pages/HomePage.vue";
import TeamPage from "@/pages/TeamPage.vue";
import PartnersPage from "@/pages/PartnersPage.vue";
import AppPage from "@/pages/AppPage.vue";
import LoginPage from "@/pages/LoginPage.vue";
import DatenschutzPage from "@/pages/DatenschutzPage.vue";
import ImpressumPage from "@/pages/ImpressumPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: HomeRoute.path,
    name: HomeRoute.name,
    component: HomePage,
  },
  {
    path: PartnersRoute.path,
    name: PartnersRoute.name,
    component: PartnersPage,
  },
  {
    path: TeamRoute.path,
    name: TeamRoute.name,
    component: TeamPage,
  },
  {
    path: AppRoute.path,
    name: AppRoute.name,
    component: AppPage,
  },
  {
    path: LoginRoute.path,
    name: LoginRoute.name,
    component: LoginPage,
  },
  {
    path: DatenschutzRoute.path,
    name: DatenschutzRoute.name,
    component: DatenschutzPage,
  },
  {
    path: ImpressumRoute.path,
    name: ImpressumRoute.name,
    component: ImpressumPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to: RouteLocationNormalized) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { left: 0, top: 0 };
    }
  },
});

export default router;

<template>
  <div class="bg-primary h-screen md:h-full">
    <PageActionTitleComponent actionTitle="Impressum und ViSdP" />
    <div class="login-page-text w-full">
      <div class="flex flex-col mx-auto w-10/12">
        <div class="px-4 py-3 bg-white rounded-lg shadow">
          <p><InkluLogo /> Landing Page (Frank Wolf)</p>
          <p>Grindelberg 70</p>
          <p>20144 Hamburg</p>
          <br />
          <p>Email für Einsendungen: info (at) inkluapp (punkt) de</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageActionTitleComponent from "@/components/PageActionTitleComponent.vue";
import InkluLogo from "@/pages/InkluLogo";

export default {
  name: "ImpressumPage",
  components: { InkluLogo, PageActionTitleComponent },
};
</script>

<style scoped></style>
